// import {sexCodeObj} from '@/constant/constant'
import { get, post } from "@/lib"
export default {
    state: {
        realNameInfo: {},
        choosedHospitalName: '',
        doctorInfo: {},
        hospitalInfo: {},
        userInfo: {}
    },
    mutations: {
        // 修改个人实名信息
        modifyRealNameInfo(state, data) {
            Object.assign(state.realNameInfo, data)
        },
        // 添加就诊卡 - 选择医院
        setChoosedHospital(state, data) {
            state.choosedHospitalName = data
        },
        ['SET_DOCTOR_INFO'](state, data) {
            state.doctorInfo = data
        },
        ['SET_HOSPITAL_INFO'](state, data) {
            state.hospitalInfo = data
        },
        ['SET_SCHEDULE'](state, data) {
            state.scheduleData = data
        },
        ['SET_USERIFNO'](state, data) {
            state.userInfo = data
        }
    },
    actions: {
        async setRealNameInfo({commit}, payload) {
            let data = await post('edit', payload)
            commit('modifyRealNameInfo', data)
        },
        async getRealNameInfo({commit}, payload) {
            let data = await get('myInfo', payload)
            commit('modifyRealNameInfo', data.data)
            return data
        },
        async queryDoctorInfo({commit}, payload) {
            // 查询医生的相关信息
            let data = await get('queryDoctorInfo', payload)
            commit('SET_DOCTOR_INFO', data)
            return data
        },
        // 获取某个医院的详情信息
        async getHospitalInfo({commit}, payload) {
            let data = await get('queryHospitalInfo', payload)
            commit('SET_HOSPITAL_INFO', data)
            return data
        },
        // 查看排班信息
        async getSchedule({commit}, payload) {
            let data = await get('querySchedule', payload)
            commit('SET_SCHEDULE', data)
        },
        // 登录、注册
        async login({commit}, payload) {
            let data =  await post('byTelephone', payload)
            console.log('data,', data)
            commit('SET_USERIFNO', data)
            return data
        },
        async register({commit}, payload) {
            let data =  await post('register', payload)
            commit('SET_USERIFNO', data)
            return data
        },
        async certain({commit}, payload) {
            let data =  await post('passport', payload)
            console.log('请求成功~', payload)
            return data
        },

        // 退出登錄
        async logout({commit}, payload) {
            let data = await post('logout', payload)
            return data
        },

        async getCode({commimt}, payload) {
            let data = await post('getCode', payload)
            return data
        },

        // 找回密碼 -- 向郵箱發送密碼
        async findPassword({commit}, payload) {
            let data = await get('sendVerificationCode', payload)
            return data
        },

        async verifyCode({commit}, payload) {
            let data = await get('verifyCode', payload)
            return data
        },

        // 重置密碼
        async resetPassport({commit}, payload) {
            let data = await post('resetPassport', payload)
            return data
        },

        // 獲取驗證碼
        async getTelCode({commit}, payload) {
            let data = await post('getTelCode', payload)
            return data
        },

         // 获取我的优惠券
         async getUserCardList({commit}, payload) {
            let data = await get('getUserCardList', payload)
            return data
        },
        // 記錄用戶點擊的功能點
        async postCode({commit}, payload) {
            let data = await get('collectionClick', payload)
            return data
        },
    }
}