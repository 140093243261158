import {get} from '@/lib/index'

export default {
    state: {
        // hospitalInfo: {},
        departmentList: [],
        doctorList: []
    },

    mutations: {
        // ['SET_HOSPITAL_INFO'](state, data) {
        //     state.hospitalInfo = data
        // },
        // 更新state数据
        ['UPDATE_STATE_DATA'](state, data) {
            Object.assign(state, data)
        }
    },

    actions: {
        // 获取医院信息
        // async getHospitalInfo({commit}, payload) {
        //     let data = await get('queryHospitalInfo', payload)
        //     commit('SET_HOSPITAL_INFO', data)
        // },
        // 获取科室列表
        async getDepartmentList({commit}, payload) {
            let data = await get('queryDepartmentList', payload)
            commit('UPDATE_STATE_DATA', {
                departmentList: data
            })
        }
    }
}