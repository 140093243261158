/**
 * file constant.js
 * 常量
*/
export const pubkey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCKZXmx/R6qvMGxbt6L8KKzMUDQz6ppauoRyUm2AauyAbQKzxsQVyuHLgor3nMsOM8B+HKTYXDzGdRl43N+d39VE+4d7oVqdGxNolTMxOO7gLaXE1hxUC32PFfbl2nVy0E+eymFG4DEBG3OSz8l/UykQRM9SmFd6uAlMoA4KiHJ1wIDAQAB`

// 适用于首页医院资讯
export const newsList = [
    {
        id: 1,
        color: 'purple',
        sign: '健康',
        title: '端午吃粽身體負擔大？　營養師教「５準則」健康吃',
        time: '2021/06/07'
    },
    {
        id: 2,
        color: 'gray',
        sign: '養生',
        title: '你每日營養攝取足夠嗎？　營養師揭「飲食指南」快記住',
        time: '2021/06/07'
    },
    {
        id: 3,
        color: 'purple',
        sign: '健康',
        title: '遠離足底筋膜炎　平時可多做伸展運動',
        time: '2021/06/08'
    },
    {
        id: 4,
        color: 'pink',
        sign: '生活',
        title: '雞蛋做早餐，減肥更有效',
        time: '2021/05/23'
    },
    {
        id: 5,
        color: 'purple',
        sign: '健康',
        title: '壓力大狂拉肚子？ 中醫這帖放鬆腸道',
        time: '2021/04/24'
    }
]
export const newsListDetail = {
    1: {
        title: '端午吃粽身體負擔大？　營養師教「５準則」健康吃',
        type: '健康',
        time: '2021/06/07',
        content: `
            <p>
            一年一度的端午節即將到來，講到端午節，不免俗的會想到一些傳統習俗，像是為了趨吉避凶的喝雄黃酒、掛艾草與菖蒲，為了紀念愛國詩人屈原的吃粽子、賽龍舟等，但如何健康吃呢？
            </p>
            <h3>健康吃粽子 5招必學</h3>
            <p>奇美醫學中心營養科營養師江奕欣來教您運用五個撇步來健康吃粽子：</p>

            <h3>撇步一：挑選種類，健康食材</h3>
            <p>粽子種類琳瑯滿目，食材也不一樣，建議可以3至4人一起分食或是選擇較小顆的粽子。</p>

            <p>此外，食材方面也可利用五穀米與糙米取代糯米，並且增加蔬菜的份量，以增添膳食纖維與微量營養素，同時，用雞肉或瘦肉取代三層肉、用植物油取代豬油，避免攝取過多飽和脂肪，增加心血管疾病風險。</p>

            <h3>撇步二：確認熱量，避免過量</h3>
            <p>大多鹹粽的熱量約落在500大卡上下，而北部粽的熱量高於南部粽，因為北部粽是將糯米與餡料用油先炒過後再包入粽葉中蒸熟，而南部粽則是生糯米與餡料包入粽葉後放入水中煮熟，故北部粽的油脂含量會比南部粽多。

            若是市售的粽子，先仔細看看包裝上的營養標示，建議一顆粽子不要超過600大卡，以取代正餐為主，不要當成點心，一天一顆即可，避免攝取過量，造成熱量超標。</p>

            <h3>撇步三：增加高纖，時令蔬果</h3>
            <p>若只吃一顆粽子是不夠均衡的，一定要再增加蔬果配菜！蔬菜類與水果類不僅可以增加膳食纖維與微量營養素，還能解油膩，增加腸胃蠕動，預防便秘。</p>

            <h3>撇步四：減少醬料，減輕負擔</h3>
            <p>吃粽子時常會搭配醬料，像是甜辣醬、醬油膏等，建議一天不超過2400毫克鈉攝取量，若吃粽子時加1湯匙的醬油膏等於佔據一整天四分之一的建議鈉攝取量了，沾醬類都屬於高鈉食物，若攝取過多可能會造成高血壓或心血管風險增加。

            而吃甜粽或鹼粽時會加的蜂蜜或果糖等，也容易因攝取過多精製糖而增加肥胖、糖尿病等風險，故建議在吃粽子時，以不沾醬或減量方式調整，避免增加身體負擔。</p>

            <h3>撇步五：完整包裝，安全衛生</h3>
            <p>現代人大多都是買現成的粽子再復熱食用，不管是在市場或是網路購買，一定要注意包裝是否完整？外觀粽葉是否有腐敗？味道是否有異常？建議購買後若發現粽子內部有黏稠液並且有腐敗的味道，就應該要丟棄，避免食用，選擇信譽良好的商家十分重要。</p>

            <h3>粽子冷藏3天要吃完 充分復熱避免變質</h3>
            <p>江奕欣營養師提醒，端午節洽於立夏之後，天氣轉熱，粽子在室溫時建議2小時內要食用完畢，若沒有要馬上吃要放置冰箱保存，建議冷藏3天內吃完，冷凍14天內吃完，且記得食用時要充分復熱，且盡快食用完畢，避免細菌滋生而變質。</p>
        `
    },
    2: {
        title: '你每日營養攝取足夠嗎？　營養師揭「飲食指南」快記住',
        type: '養生',
        time: '2021/06/07',
        content: `
            <p>
            許多人到營養諮詢門診開口就詢問：「營養師我的營養攝取足夠嗎?」，或者年長的婆婆媽媽也常常問「什麼是膳食纖維?膳食纖維可以預防癌症嗎?」，至於有家長則會像營養師請教：「家中小朋友不喜歡吃蔬菜可以改成只吃水果嗎?」等疑問，營養師逐一解答。
            </p>
            <h3>近全數國人乳品攝取不足 女性營養攝取量嚴重不足</h3>
            <p>
            樂生療養院營養室主任蘇嘉華指出，依據國民健康署2013-2016年國民營養健康狀況變遷調查結果，我國19~64歲成人每日平均乳品攝取不足1.5杯高達99.8%，堅果種子不足1份的為91%，蔬菜攝取量不足3份為86%，水果攝取量不足2份亦達86%。
            </p>
            <p>其中，女性攝取量亦嚴重不足，19-64歲女性蔬菜攝取量不足3份達88.1%，水果攝取量不足2份達85.1%，乳品攝取不足1.5份達99.9%，堅果種子攝取量不足1份達89.7%。</>
            <h3>膳食纖維 促進排便、預防癌症</h3>
            <p>蘇嘉華營養師表示，纖維主要來源為蔬菜、水果、全榖類和豆類，其中「膳食纖維」為植物細胞壁、細胞間質的一些無法被人體消化及吸收利用的多醣類 （如纖維素、半纖維素、果膠質、樹膠質）及木質素，在許多研究顯示，膳食纖維可以預防癌症發生，也可以增加糞便量和加速身體中廢物的排除，減少腸道與致癌因子的接觸，並會改變腸中的細菌種類，提供短鍵脂肪酸，維持腸內黏膜的正常分化，避免癌細胞的形成。</p>
            <p>另外，攝取高纖食物，如蔬菜、水果、全穀類等，可以預防大腸直腸癌、食道癌、胃癌、攝護腺癌、子宮內膜癌以及卵巢癌的發生，而飲食中的纖維會中斷雌激素（estrogen）由肝門循環再吸收回身體，進而預防乳癌的發生。</p>
            <h3>水果無法取代蔬菜 兩者營養成分不同</h3>
            <p>很多家長帶著小朋友前來諮詢，小朋友不愛吃蔬菜，比較喜歡吃水果，可以用水果代替嗎？蘇嘉華營養師強調，答案是不行的！兩者營養的成分是不同的，也無法相互取代。</p>
            <p>此外，蘇嘉華營養師提供一個撇步給家長，如製作咖哩飯食，將濃稠添加物減少，可將洋蔥或紅蘿蔔打成泥和入，提升濃稠又有蔬菜的營養，健康又美味。</p>
        `
    },
    3: {
        title: '遠離足底筋膜炎　平時可多做伸展運動',
        type: '健康',
        time: '2021/06/08',
        content: `
            <p>
            長時間站立或行走，甚至是扁平足，很容易就埋下足底筋膜炎的危險因子，患者除了會感到明顯足跟疼痛，生活品質與活動力都會受到影響。南投醫院復健科周建文主任表示，運動傷害、體重過重、穿著高跟鞋或是老年人因退化導致脂肪墊的萎縮等因素，都可能造成足底筋膜炎，必要時可藉由體外震波治療獲得舒緩。
            </p>
            <h3>婦人一下床足後跟刺痛 原來是足底筋膜炎惹禍</h3>
            <p>
            一名55歲陳姓婦人，一早起床踩地就感到足底後跟強烈刺痛，多走動後才逐漸舒緩，但症狀仍未改善，就醫檢查後確診為足底筋膜炎，經體外震波治療，加上物理治療師提供的伸展運動，總算改善筋膜炎不適。周建文主任指出，治療初期筋膜炎患者，通常藉由休息減少走動或冰敷方式就能幫助舒緩，也可做像腳踝運動、跟腱牽拉運動等配合傳統復健治療獲改善。
            </p>
            <h3>體外震波治療足底筋膜炎 治療效果達八成以上</h3>
            <p>
            周建文主任說明，若仍無法改善症狀的患者，則建議可採以體外震波治療，包括鈣化性肌腱炎及肱上髁炎等都適合採以此治療，臨床治療效果達八成以上，因震波治療的特性先讓組織微破壞，促進好的發炎，增進血液循環等方式讓人體修復，因此治療後可能有悶脹或酸痛情況，但約1至2天就會消失，通常組織的修復及再生通常在一到二週後就會開始，約一個月後達到高峰。
            </p>
            <h3>避免長時間行走且多伸展 有效保護足底筋膜</h3>
            <p>
            約七成接受體外震波治療患者，在接受治療3次後就能明顯改善疼痛感，且因爲非侵入性保守治療，加上無傷口、恢復快，是患者的一大選擇。周建文主任強調，預防勝於治療，平時應避免長時間行走和站立、過度拉扯足底筋膜，也可自行做肌力和伸展訓練，並選擇適合的鞋子，才能遠離足底筋膜炎找上門。
            </p>
        `
    },
    4: {
        title: '雞蛋做早餐，減肥更有效',
        type: '生活',
        time: '2021/05/23',
        content: `
            <p>一份最新研究顯示，早上吃一顆雞蛋比吃麥片、穀類更能幫助抑制食慾，在午餐前感覺較不容易餓且更有飽肚感，使進食午餐的份量比較少，達到減肥效果。</p>
            <p>醫師指出，雞蛋屬於優質蛋白質，能讓刺激饑餓感的荷爾蒙分泌下降、瘦素上升，加上消化時間比碳水化合物長，使人不容易產生飢餓感覺。</p>
            <h3>研究計劃</h3>
            <p>有20位過重或肥胖的人士參與此研究，一組一整個星期都吃有蛋的早餐，另一組則吃麥片，並控制每個人三餐攝取的碳水化合物、蛋白質以及脂肪含量；而一顆大的雞蛋熱量大約有70卡路里，約含6克蛋白質、5克脂肪、186毫克的膽固醇。</p>
            <p>在研究的第一天與最後一天中午則吃自助餐，研究人員分別測試參與者在吃了早餐與午餐後，感覺有多飽，記錄自助餐吃了多少熱量，並抽血檢驗刺激飢餓感的荷爾蒙 "ghrelin" 以及飽肚感的荷爾蒙 "PYY3-36" 的分泌量。</p>
            <h3>研究結果發現</h3>
            <p>早餐吃蛋的人，在午餐前，自我感覺較有飽肚感，吃自助餐的份量比那些吃麥片的人少，且吃蛋的人在早餐與午餐之間的3小時內，荷爾蒙分泌量也顯示，較不餓且更有飽肚感。</p>
            <p>專家表示：雞蛋是優質蛋白質來源，可以延長飽肚感的時間，因為胃腸要消化、排空蛋白質需要至少3到4小時，比碳水化合物的2到3小時長，讓減肥者在早餐到午餐之間的空檔，不容易感覺「飢腸轆轆」的難熬。從荷爾蒙角度來看，早餐吃顆雞蛋，還能讓體內的飢餓荷爾蒙下降、瘦素上升，更能克制不斷想吃餅乾、蛋糕或巧克力等零食止飢的慾望。</p>
            <p>專家提醒：早餐吃蛋只是輔助的小幫手，總熱量的管控絕對比吃什麼重要，在營養均衡、規律運動之外，減少攝取熱量的同時，一定要兼顧三餐糖類、脂肪、蛋白質（5：3：2）的分量來吃，才能夠健康減肥。</p>
        `
    },
    5: {
        title: '壓力大狂拉肚子？ 中醫這帖放鬆腸道',
        type: '健康',
        time: '2021/04/24',
        content: `
            <p>你是否老是覺得腹痛、脹氣、一緊張就想上廁所？現代人只要吃得下、排便還算ok就覺得正常，往往容易忽視腸胃問題。其實許多人的腸胃已經外強中乾，加上不良的習慣或遭遇環境上的刺激，各種不舒服症狀便開始展現，做遍各種檢查，才發現自己得了腸躁症。 肝鬱脾虛體質 易釀腸躁症上身 腸躁就是腸胃過度敏感又反應過度，臺北市立聯合醫院林森中醫昆明院區中醫科醫師趙品諭表示，中醫角度認為這是肝鬱脾虛。中醫講的肝，有能力可以疏理身體的氣、血、津液，肝鬱的人往往就是呈現一種身心緊繃的狀態，也容易遭受影響出現各種失調的症狀，如失眠、月經不規則。</p>
            <p>此外，趙品諭中醫師指出，中醫的脾，能運化水穀精微，以現代醫學角度就是腸胃的消化及吸收營養功能，脾虛的人往往就是呈現一種與消化道有關的不正常的情況。</p>
            <h3>紓解腸躁症狀 中醫「逍遙散」助改善</h3>
            <p>趙品諭中醫師說，套用近幾年流行的一種概念，腸胃是身體的第二個大腦，它會透過「腦腸軸線」與人的身心交互影響；換句話說，自閉症、慢性疲勞、腸躁症、憂鬱症、焦慮症等盛行率極高的身心疾病，都和腦腸軸線相關。</p>
            <p>那什麼方法可以安撫它呢？腸胃有不適症狀時，應向醫師求診。趙品諭中醫師分享，中醫對於肝鬱脾虛，可用中醫藥方「逍遙散」幫忙，方中柴胡疏肝解鬱，當歸、白芍養血柔肝，加薄荷少許，可以加強疏肝解鬱效果，白朮、茯苓益氣健脾，再用乾薑和中益胃，最後加甘草調和諸藥；諸藥合用，使肝氣得疏，脾虛得補。 養成好習慣 與腸道和平共處</p>
            <p>因每個人體質不同，中醫還可以從很多角度來調理腸躁問題，但趙品諭中醫師呼籲，最重要的還是要謹慎注意自己的飲食，以及適度地調適身心壓力，才能讓自己與腸道達成和諧共處。</p>
        `
    }
}

// 证件类型
export const cardTypeList = [{
    val: '就诊卡',
    code: 1
}, {
    val: '身份证',
    code: 2
}, {
    val: '护照',
    code: 3
}, {
    val: '户口簿',
    code: 4
}, {
    val: '军官证',
    code: 5
}, {
    val: '士兵证',
    code: 6
}, {
    val: '回乡证',
    code: 7
}, {
    val: '通行证',
    code: 8
}, {
    val: '临时身份证',
    code: 9
}, {
    val: '外国人居留证',
    code: 10
}, {
    val: '警官',
    code: 11
}, {
    val: '医保卡',
    code: 12
}, {
    val: '其他证件证',
    code: 13
}, {
    val: '住院号',
    code: 14
}]

export const cartTypeObj = {
    1: '就诊卡',
    2: '身份证',
    3: '护照',
    4: '户口簿',
    5: '军官证',
    6: '士兵证',
    7: '回乡证',
    8: '通行证',
    9: '临时身份证',
    10: '外国人居留证',
    11: '警官',
    12: '医保卡',
    13: '其他证件证',
    14: '住院号'
}

// 性别
export const sexObj = {
    1: '男',
    2: '女'
}

export const sexCodeObj = {
    '男': 1,
    '女': 2
}

export const sexList = [{
    val: '男',
    code: 1
}, {
    val: '女',
    code: 2
}]

// 城市中文 -- code对应
export const cityCodeMapping = {
    "福州": "3910",
    "厦门": "3930",
    "莆田": "3940",
    "三明": "3950",
    "宁德": "4030",
    "泉州": "3970",
    "漳州": "3990",
    "龙岩": "4050",
    "南平": "4010"
}

// 医院性质
export const hospitalType = {
    1: '公立',
    2: '民营',
    3: '尚未配置'
}

// 是否支持退号
export const isSupportTuiHao = {
    1: '支持退号',
    2: '不支持退号'
}

// 科室类别
export const typeId = {
    1: '门诊科室',
    2: '检查科室',
    3: '住院科室'
}

export const stateObj = {
    1: '未开放预约',
    2: '开放预约',
    3: '未开放预约'
}

// 優惠券列表
export const yhjCardList = [{
    id: 1,
    title: '免費乘公交碼',
    titleDesc: '台胞可用',
    cardName: '出行劵',
    desc1: '使用平台：全平台',
    desc2: '有效期至: 2021-09-18'
}, {
    id: 2,
    title: '免费体检套餐',
    titleDesc: '台胞可用',
    cardName: '體檢劵',
    desc1: '使用平台：全平台',
    desc2: '有效期至: 2021-09-18'
}]

export const yhjDetailObj = {
    1: {
        id: 1,
        title: '免費乘公交碼',
        titleDesc: '台胞可用',
        cardName: '出行劵',
        desc1: '使用平台：全平台',
        desc2: '有效期至: 2021-09-18',
        content: `
        <div>
        <h3>使用方式</h3>
        <span>台胞用户下载台陸通->點擊台医通->注册成功->赠送公交码（10次公交码）->台胞登录支付宝選擇出行使用。需下载支付宝显示公交码。</span>
        `
    },

    2: {
        id: 2,
        title: '免费体检套餐',
        titleDesc: '台胞可用',
        cardName: '體檢劵',
        desc1: '使用平台：全平台',
        desc2: '有效期至: 2021-09-18',
        content: `
        <div>
            <h3>使用方式</h3>
            <span>
                線上：臺胞下載、註冊成功“臺醫通”->獲取體檢優惠券->體檢項目（備註：免費一項體檢項目）
                線下：臺胞前往平潭寶島醫院->出示優惠券->選擇體檢項目->完成體檢
            </span>
        </div>
        <div>
            <h3 style="margin-top: 23px;">體檢機構</h3>
            <span>平潭寶島醫院</span>
        </div>
        <div>
            <h3 style="margin-top: 23px;" class="title">體檢機構</h3>
            <ul>
                <li>台胞注册台医通赠送套餐项目：</li>
                <li>1、腹部彩超（肝胆胰脾肾）98元</li>
                <li>2、前列腺彩超98元</li>
                <li>3、子宫附件彩超140元</li>
                <li>4、甲状腺彩超60</li>
                <li>5、常规心电图：29元</li>
                <li>6、颈椎治疗套餐价234元</li>
                <li>7、腰椎治疗套餐价292.5元</li>
                <li>8、肩周治疗套餐价292.5元</li>
                <li>9、双膝关节治疗套餐价334元</li>
            </ul>
        </div>
        <div>
            <h3 style="margin-top: 23px;" class="title">使用規則</h3>
            <span>免费一项体检项目</span>
        </div>
        `
    }
}
