import {get} from '@/lib/index'
export default {
    state: {
        doctorList: []
    },
    mutations: {
        ['UPDATE_STATE_DATA'](state, data) {
            Object.assign(state, data)
        }
    },
    actions: {
        // 查询医生列表信息
        async getSimpleDoctorList({commit}, payload) {
            let data = await get('querySimpleDoctorList', payload)
            commit('UPDATE_STATE_DATA', {
                doctorList: data
            })
        }
    }
}