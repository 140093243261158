export default {
    state: {
        recordObj: {
            cardType: 1,
            sex: 1
        }
    },
    mutations: {
        updateRecordObj(state, data) {
            Object.assign(state.recordObj, data)
        }
    },
    actions: {
        
    }
}