import { get } from "../../lib"

export default {
    state: {
        recordDetail: {},
        doctorInfo: {}
    },

    mutations: {
        ['SET_RECORD_DETAIL'](state, data) {
            state.recordDetail = data
        },
        ['SET_DOCTOR_INFO'](state, data) {
            state.doctorInfo = data
        }
    },

    actions: {
        async getRecordDetail({commit}) {
            // let data = this.$get('', payload)
            let data = {
                hospitalName: '福建人民医院',
                department: '耳鼻喉科',
                doctor: '沈凡',
                date: '2021-03-05 上午',
                time: '10:20',
                number: 32,
                card: 'A0312341',
                orderNumber: '123445234',
                createTime: '2021-03-02 12:01:01',
                name: 'zs',
                cardId: '350*****123',
                phoneNumber: '15212340987'
            }

            commit('SET_RECORD_DETAIL', data)
        },
        // 获取医生的信息
        async queryDoctorInfo({commit}, payload) {
            // 查询医生的相关信息
            let data = await get('queryDoctorInfo', payload)
            console.log('info,,,,,,', data)
            commit('SET_DOCTOR_INFO', data)
        },
        
    }
}