export default {
    state: {
        personalObj: {
            name: '张三',
            sex: 1,
            date: '1992-05-03',
            phoneNumber: '15212345678',
            taiCertificate: 'A5123', //台胞证书
            cardNumber: '0001243214',
            taiAddress: '台湾市冬季台湾市冬季台湾市冬季',
            nowAddress: '福建省福州市软件园',
            email: '124@sina.com',
            hospitalCard: '' 
        }
    },
    mutations: {
        modifyPersonalObj(state, data) {
            Object.assign(state.personalObj, data)
        }
    },
    actions: {

    }
}