export default {
    state: {},
    mutations: {},
    actions: {
        async deleteCardInfo({commit}, payload) {
            console.log(commit, payload)
            // 此处调用删除卡片接口
            return Promise.resolve('success')
        }
    }
}