module.exports = {
    queryHospitalList: `queryHospitalList`
}




















// import axios from 'axios'
// import service from './request'
// function req(method, apiname, params = {}, opt = {}) {
//     console.log('req,', process.env)
//     return new Promise((resolve, reject) => {
//         const onSuccess = res => {
//             let data = res && res.data;
//             if (data && (data.errorCode === 0 || data.code === '200')) {
//                 resolve(data);
//             }
//             else if (data.errorCode === 14001) {
//                 location.reload();
//             }
//             // 无权访问
//             else if (data.errorCode === 403) {
//                 location.href = location.protocol + '//' + location.host + '/error';
//             }
//             else {
//                 reject(data);
//             }
//         };
//         const onFail = err => reject(err); // 系统异常，是否直接给出提示

//         if (method === 'post') {
//             service({
//                 method: 'post',
//                 url: apiList[apiname],
//                 params,
//             }).then(onSuccess, onFail)
//             // axios[method](apiList[apiname], Object.assign(params, opt)).then(onSuccess, onFail);
//         } else {
//             // add ts when get request
//             // params._ts = +new Date();
//             service({
//                 method: 'post',
//                 url: apiList[apiname],
//                 params,
//             }).then(onSuccess, onFail)
//             // axios[method](apiList[apiname], Object.assign({
//             //     params
//             // }, opt)).then(onSuccess, onFail);
//         }
//     });
// }

// export function get(apiname, params, opt) {
//     console.log('get')
//     return req('get', apiname, params, opt);
// }

// export function post(apiname, params, opt) {
//     return req('post', apiname, params, opt);
// }

// export function testFn() {
//     return axios.get('/data?id=7&key=R5RTF4G5F5H6&name=shandong')
// }

// export function getTestData() {
//     return service({
//         url: '/data?id=7&key=R5RTF4G5F5H6&name=shandong',
//         method: 'get'
//     })
// }

// export function postTestData(data) {
//     return service({
//         url: '/data?id=7&key=R5RTF4G5F5H6&name=shandong',
//         method: 'post',
//         data
//     })
// }