// 过滤recordList
let filterRecordList = (list, tab) => {
    return list.filter(item => {
        return item.orderStatus == tab
    })
}

export default {
    state: {
        recordList: [],
        storeRecordList: []
    },

    mutations: {
        filterRecordList(state, tab) {
            if(tab == '0') {
                return state['recordList'] = state['storeRecordList']
            }
            state.recordList = filterRecordList(state.storeRecordList, tab)
        },
        ['SET_RECORD_LIST'](state, data) {
            state.recordList = filterRecordList(data, '1')
            state.storeRecordList = data
        }
    },

    actions: {
        getRecordList({commit}) {
            // 获取我的预约记录 全部记录
            // let data = this.$get('', payload)
            let data = [{
                appointId: 1,
                orderStatus: 1,
                hospitalName: '福建人民医院',
                departmentName: '耳鼻喉科',
                status: '已预约',
                imgSrc: require('@/assets/images/avadar.png'),
                doctorName: '沈帆',
                commandTime: '2021-03-05 10:20:02',
                people: '董一明(136****2134)',
                seqNo: 32,
                name: '李四'
            }, {
                appointId: 2,
                orderStatus: 2,
                hospitalName: '福建人民医院',
                departmentName: '耳鼻喉科',
                status: '已预约',
                imgSrc: require('@/assets/images/avadar.png'),
                doctorName: '沈帆',
                commandTime: '2021-03-05 10:20:02',
                people: '董一明(136****2134)',
                seqNo: 32,
                name: '李四'
            }, {
                appointId: 3,
                orderStatus: 3,
                hospitalName: '福建人民医院',
                departmentName: '耳鼻喉科',
                status: '已预约',
                imgSrc: require('@/assets/images/avadar.png'),
                doctorName: '沈帆',
                commandTime: '2021-03-05 10:20:02',
                people: '董一明(136****2134)',
                seqNo: 32,
                name: '李四'
            }]
            commit('SET_RECORD_LIST', data)
        },
        async cancelAppoint(context, payload) {
            console.log(payload)
            return new Promise(resolve => {
                resolve({
                    code: 200,
                    message: '成功'
                })
            });
            // return this.$post('/cancelAppoint', payload)
        }
    }
}