import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'DefaultIndex',
    redirect: '/index'
  },
  {
    path: '/modifyPassword',
    name: 'ModifyPassword',
    component: () => import('../views/ModifyPassword/modifyPassword.vue'),
    meta: {
      title: '修改密碼'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/login.vue'),
    meta: {
      title: '密碼登入'
    }
  },
  {
    path: '/read',
    name: 'Read',
    component: () => import('../views/Login/read.vue'),
    meta: {
      title: '用戶協議'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register/register.vue'),
    meta: {
      title: '註冊'
    }
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/Index/index.vue'),
    meta: {
      requireAuth: true,
      title: '主頁'
    }
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import(/* webpackChunkName: "test" */ '../views/Mine/mine.vue'),
    meta: {
      requireAuth: true,
      title: '我的'
    }
  },
  {
    path: '/mineDoctor',
    name: 'MineDoctor',
    component: () => import(/* webpackChunkName: "doctor" */ '../views/MineDoctor/mineDoctor.vue'),
  },
  {
    path: '/mineInfo',
    name: 'MineInfo',
    component: () => import(/* webpackChunkName: "mineInfo" */ '../views/MineInfo/mineInfo.vue'),
    meta: {
      requireAuth: true,
      title: '我的信息'
    }
  },
  {
    path: '/mineRecord',
    name: 'MineRecord',
    component: () => import('../views/MineRecord/mineRecord.vue')
  },
  {
    path: '/offlineApply',
    name: 'OfflineApply',
    component: () => import('../views/OfflineApply/offlineApply.vue')
  },
  {
    path: '/selectDepartment',
    name: 'SelectDepartment',
    component: () => import('../views/SelectDepartment/selectDepartment.vue')
  },
  {
    path: '/selectDoctor',
    name: 'SelectDoctor',
    component: () => import('../views/SelectDoctor/selectDoctor.vue')
  },
  {
    path: '/appointment',
    name: 'Appointment',
    component: () => import('../views/Appointment/appointment.vue')
  },
  {
    path: '/doctorDetail',
    name: 'DoctorDetail',
    component: () => import('../views/AppointmentInfo/doctorDetail.vue')
  },
  {
    path: '/appointRules',
    name: 'AppointRules',
    component: () => import('../views/AppointmentInfo/appointRules.vue')
  },
  {
    path: '/record',
    name: 'Record',
    component: () => import('../views/Record/record.vue')
  },
  {
    path: '/addCard/:type', // 1: 添加 2： 查看
    name: 'AddCard',
    component: () => import('../views/AddCard/addCard.vue')
  },
  {
    path: '/appointResult/:status',
    name: 'AppointResult',
    component: () => import('../views/AppointResult/appointResult.vue')
  },
  {
    path: '/appointmentInfo/:status',
    name: 'AppointmentInfo',
    component: () => import('../views/AppointmentInfo/appointmentInfo.vue')
  },
  {
    path: '/modifyAddress',
    name: 'ModifyAddress',
    component: () => import('../views/MineInfo/modifyAddress.vue')
  },
  {
    path: '/discoverDetail',
    name: 'DiscoverDetail',
    component: () => import('../views/DiscoverDetail/discoverDetail.vue'),
    meta: {
      requireAuth: true,
      title: '資訊詳情'
    }
  },
  {
    path: '/manageCard',
    name: 'manageCard',
    component: () => import('../views/ManageCard/manageCard.vue')
  },
  {
    path: '/cardDesc',
    name: 'CardDesc',
    component: () => import('../views/CardDesc/cardDesc.vue')
  },
  {
    path: '/onlineApply',
    name: 'OnlineApply',
    component: () => import('../views/OnlineApply/onlineApply.vue')
  },
  {
    path: '/container',
    name: 'Container',
    component: () => import('../views/Container/container.vue')
  },
  {
    path: '/jkContainer',
    name: 'JkContainer',
    component: () => import('../views/Container/jkContainer.vue')
  },
  {
    path: '/mineContainer',
    name: 'MineContainer',
    component: () => import('../views/MineContainer/mineContainer.vue')
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('../views/Setting/setting.vue'),
    meta: {
      requireAuth: true,
      title: '設置'
    }
  },
  {
    path: '/bx',
    name: 'Bx',
    component: () => import('../views/Bx/bx.vue')
  },
  {
    path: '/bxDetail',
    name: 'BxDetail',
    component: () => import('../views/Bx/bxDetail.vue')
  },
  {
    path: '/noOpen',
    name: 'NoOpen',
    component: () => import('../components/NoOpen/noOpen.vue')
  },
  {
    path: '/loginByCode',
    name: 'LoginByCode',
    component: () => import('../views/Login/loginByCode.vue'),
    meta: {
      title: '短信登入'
    }
  },
  {
    path: '/forgetPassword',
    name: 'ForgetPassword',
    component: () => import('../views/Login/forgetPassword.vue'),
    meta: {
      title: '忘記密碼'
    }
  },
  {
    path: '/common',
    name: 'Common',
    component: () => import('../views/Index/common.vue')
  },
  {
    path: '/three',
    name: 'Three',
    component: () => import('../views/Index/three.vue')
  },
  {
    path: '/mineCard',
    name: 'MineCard',
    component: () => import('../views/MineCard/mineCard.vue'),
  },
  {
    path: '/mineCard/detail/:id',
    name: 'mineCardDetail',
    component: () => import('../views/MineCard/mineCardDetail.vue')
  },
  {
    path: '*',
    component: () => import('../components/NotFound/notFound.vue')
  }
]
// mode: 'history',
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
