import Vue from 'vue'
import Vuex from 'vuex'
import layout from '../views/Layout/store'
import changeStoreFuTitle from '../views/Index/store'
import mineInfo from '../views/MineInfo/store'
import offlineApply from '../views/OfflineApply/store'
import mineRecord from '../views/MineRecord/store'
import appointmentInfo from '../views/AppointmentInfo/store'
import selectDepartment from '../views/SelectDepartment/store'
import mineDoctor from '../views/MineDoctor/store'
import record from '../views/Record/store'
import addCard from '../views/AddCard/store'
import selectDoctor from '../views/SelectDoctor/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    layout,
    changeStoreFuTitle,
    mineInfo,
    offlineApply,
    mineRecord,
    appointmentInfo,
    selectDepartment,
    mineDoctor,
    record,
    addCard,
    selectDoctor
  }
})
