export default {
    state: {
        doctorList: []
    },
    mutations: {
        ['SET_DOCTOR_LIST'](state, data) {
            state.doctorList = data
        }
    },
    actions: {
        async getDoctorList({commit}) {
            let data = [{
                doctorId: 1,
                doctorName: '林本良',
                hospitalName: '福建人民医院',
                departmentName: '耳鼻喉科',
                leftNum: 0,
                level: '主任医生'
            }, {
                doctorId: 2,
                doctorName: '沈静',
                hospitalName: '福建人民医院',
                departmentName: '心脑血管',
                leftNum: 1,
                level: '实习医生'
            }]
            commit('SET_DOCTOR_LIST', data)
        }
    }
}