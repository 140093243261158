import Vue from 'vue'
import App from './App.vue'
import 'vant/lib/index.css'
import JSEncrypt from 'jsencrypt';

import VueRouter from 'vue-router'
import weui from 'weui.js'
import 'weui'
// import './assets/css/jquery-weui.css'
import './assets/css/base.css'

import './assets/css/common.scss'
import './assets/css/weui.css'
Vue.prototype.$weui = weui
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

import { DatetimePicker } from 'vant';
import { Popup } from 'vant';
import {pubkey} from './constant/constant'

Vue.use(DatetimePicker)
Vue.use(Popup)
Vue.use(VueRouter)
// import './utils/keyboard'
import {initRem} from './utils/setRem'
initRem()

window.addEventListener('resize', function() {
  initRem()
})
import {Button, Toast, Dialog, Loading, Overlay} from 'vant';
import router from './router'
import store from './store'
Vue.use(Loading);
Vue.use(Button)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(Overlay)

Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;

import {get, post} from '@/lib/index'
Vue.prototype.$get = get;
Vue.prototype.$post = post;

Vue.config.productionTip = false

Vue.prototype.$getRsaCode = function(str){ // 注册方法
  let encryptStr = new JSEncrypt();
  encryptStr.setPublicKey(pubkey); // 设置 加密公钥
  let  data = encryptStr.encrypt(str.toString());  // 进行加密
  return data;
}

router.beforeEach((to, from, next) => {
  // if(localStorage.getItem('user_token') && to.path == '/login') {
  //   next({
  //     path: '/index'
  //   })
  // }else
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if(to.meta.requireAuth) {
    if(localStorage.getItem('user_token')) {
      next()
    }else {
      next({
        path: '/login'
      })
    }
  }else {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
