import {getTestData} from '../../lib/api'
function testFn() {
    return new Promise((resolve) => {
        setTimeout(() => resolve('123'), 2000)
    })
}

testFn().then(res => {
    console.log('res,', res)
})

export default {
    state: {
        storeFuTitle: ''
    },
    mutations: {
        ['CHANGE_FU_TITLE'](state,data) {
            state.storeFuTitle = data;
        }
    },
    actions: {
        async changeStoreFuTitle({commit}, payload) {
            // let data = await testFn()
            let data = await getTestData()
            console.log('data,', data, payload)
            commit('CHANGE_FU_TITLE', data)
        }
    }
}