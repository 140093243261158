
import {get} from '@/lib/index'
export default {
    state: {
        hospitalName: '',
        hospitalList: []
    },

    mutations: {
        chooseHospital(state, data) {
            state.hospitalName = data;
        },
        ['SET_HOSPITAL_LIST'](state,data) {
            state.hospitalList = data
        }
    },

    actions: {
        async getHospitalList({commit}, payload) {
            // 获取医院列表
            let data = await get('queryHospitalList', payload)
            console.log('data,', data)
            commit('SET_HOSPITAL_LIST', data)
        }
    }
}