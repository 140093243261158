/**
 * @file request
*/

import axios from 'axios';
import {getHeader} from '../utils/setHeader'

axios.defaults.timeout = 500000000;
// axios.defaults.baseURL = '';
axios.defaults.baseURL = process.env.VUE_APP_baseURL;
// axios.defaults.baseURL = '';
// axios.defaults.headers = {
//     'Content-Type': 'application/x-www-form-urlencoded'
// }
// request拦截器
axios.interceptors.request.use(
    config => {
        // 这个是取消的重点
        // config.cancelToken = new CancelToken(cancel => {
        //     CancelStore._axiosPromiseCancel.push(cancel)
        // })
        console.log('进来啦,', config)
        if(config.url == apiList['getTelCode']) {
            config.url = `${config.url}?telephone=${config.data.telephone}`
        }
        // config.url.replace()
        // let url = config.url.replace(config.baseURL, '');
        // console.log('url,', url)
        // let code = config.code;
        // 让每个请求携带自定义签名
        config.headers = getHeader()
        return config
    },
    error => {
        console.log('error1', error);
        return Promise.reject(error)
    }
)

// // response拦截器
// axios.interceptors.response.use(
//     response => {
//         if(response.config.responseType === 'blob') {
//             if(response.status === 200) {
//                 return response
//             }else {
//                 return Promise.reject()
//             }
//         }else {
//             const res = response.data;
//             if(res.code=== 200) {
//                 return res
//             }else {
//                 return Promise.reject()
//             }
//         }
//     },
//     error => {
//         console.log('error3', error);
//         return Promise.reject(error)
//     }
// )

let apiList = {
    /*<dev>*/
    test: '/api/data',
    openApi: '/api/getJoke',
    queryHospitalList: '/queryHospitalList', // 获取医院列表'/api/gh/baseInfo/queryHospitalList'
    queryHospitalInfo: '/queryHospitalInfo', //获取医院详情 '/api/gh/baseInfo/queryHospitalInfo'
    queryDepartmentList: '/queryDepartmentList', //获取科室信息 '/api/gh/baseInfo/queryDepartmentList'
    querySimpleDoctorList: '/querySimpleDoctorList', // 查询医生列表信息 'baseinfo/DoctorInfoApi/querySimpleDoctorList'
    queryDoctorInfo: '/queryDoctorInfo', // 查看医生详情 '/api/gh/baseInfo/queryDoctorInfo'
    querySchedule: '/querySchedule', // 查看排班信息 '/api/gh/registrationBooking/querySchedule'
    byTelephone: '/user/api/auth/login/account',// 登录 '/api/auth/login/account'
    register: '/user/api/user/register', // 注册
    myInfo: '/user/api/user/myInfo', //个人信息
    edit: '/user/api/user/edit', // 编辑个人信息
    passport: '/user/api/user/passport', // 更改密码
    logout: '/user/api/auth/logout', // 退出,
    sendVerificationCode: '/user/api/user/sendVerificationCode', // 找回密碼 -- 向郵箱發送驗證碼
    verifyCode: '/user/api/user/verifyCode', // 檢驗驗證碼
    resetPassport: '/user/api/user/reset/passport', // 重置密碼
    getTelCode: '/sms/sendMessage/sendVerificationCode', //獲取驗證碼
    getUserCardList: '/user/api/user/getUserCardList', // 获取优惠券列表
    collectionClick: '/collection/click', // 收集用戶點擊信息
};

export const apiObj = apiList;

function req(method, apiname, params = {}, opt = {}) {
    console.log('req,', process.env)
    return new Promise((resolve, reject) => {
        const onSuccess = res => {
            let data = res;
            if(res && !res.data) {
                // token过去页面跳回首页
                window.location.href = 'https://taiyitong.hxcx.com.cn/#/login'
            }
            // 此处采用正式接口的是否返回的是code，这个看下要怎么处理
            if (data && (data.status === 0 || data.status === 200)) {
                resolve(data.data);
            }
            else if (data.status === 14001) {
                location.reload();
            }
            // 无权访问
            else if (data.status === 403) {
                location.href = location.protocol + '//' + location.host + '/error';
            }
            else {
                reject(data);
            }
        };
        const onFail = err => {
            console.log('fail,', err)
            reject(err)
        }; // 系统异常，是否直接给出提示

        if (method === 'post') {
            axios[method](apiList[apiname], Object.assign(params, opt)).then(onSuccess, onFail);
        } else {
            // add ts when get request
            params._ts = +new Date();
            axios[method](apiList[apiname], Object.assign({
                params
            }, opt)).then(onSuccess, onFail);
        }
    });
}

export function get(apiname, params, opt) {
    console.log('get')
    return req('get', apiname, params, opt);
}

export function post(apiname, params, opt) {
    // opt = {
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'  //指定消息格式
    //     }
    // }
    return req('post', apiname, params, opt);
}
